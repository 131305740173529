.message-list {
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;
    border-right: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 0;
    padding: 0;
    height: 100%;
}


.message-list-inner {
    border-left: 2px solid #404040;
    border-top: 2px solid #404040;
    border-right: 2px solid #f4f4f4;
    border-bottom: 2px solid #f4f4f4;
    background: #fff;
    height: 100%;
    padding: 1em;
    margin: 0;
    overflow-y: scroll;
}